<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
//定义一个函数判断是手机端还是pc端
function isMobile(us) {
	if (us.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
		return true; // 移动端
	} else {
		return false; // PC端
	}
}
function getUserAgent() {
	var ua = window.navigator.userAgent;
	return ua;
}

function getType(ua) {
	if (ua.indexOf('OKApp') > -1) {
		return true;
	} else if (ua.indexOf('TokenPocket') > -1) {
		return true;
	} else if (ua.indexOf('Token') > -1) {
		return true;
	} else if (window.ethereum) {
		return true;
	}

	// if(window.web3?.currentProvider?.isCoinbaseWallet){
	// 	return false;
	// }else

	return false
	if (ua.toLowerCase().match(/MicroMessenger/i) == "micromessenger") {//微信内置浏览器
		return false;
	} else if (ua.indexOf('MQQBrowser') > -1) {//qq内置浏览器
		return false;
	} else if (ua.indexOf('UCBrowser') > -1) {//UC浏览器
		return false;
	} else if (ua.indexOf("Opera") > -1) {//UC浏览器
		return false;
	} else if (ua.indexOf('Opera') > -1) {//Opera浏览器
		return false;
	} else if (ua.indexOf("Edg") > -1) {//edge浏览器
		return false;
	} else if (ua.indexOf("Firefox") > -1) {//firefox浏览器
		return false;
	} else if (ua.indexOf("Vivo") > -1 || ua.indexOf("VivoBrowser") > -1) {//vivo浏览器
		return false;
	} else if (ua.indexOf('Huawei') > -1 || ua.indexOf('HuaweiBrowser') > -1) {//华为浏览器
		return false;
	} else if (ua.indexOf('XiaoMi') > -1 || ua.indexOf('MiuiBrowser') > -1) {//小米浏览器
		return false;
	} else if (ua.indexOf('Alipay') > -1) {//支付宝浏览器
		return false;
	} else if (ua.indexOf('Presto') > -1) {//Presto浏览器
		return false;
	} else if (ua.indexOf('Quark') > -1) {//夸克浏览器
		return false;
	} else if (ua.toLowerCase().indexOf('360se') != -1 || ua.toLowerCase().indexOf('360ee') != -1) {
		return false;
	} else if (ua.indexOf('GoldBrowser') > -1) {//悟空浏览器
		return false;
	} else if (ua.indexOf('minibrowser') > -1 || ua.indexOf('haowangzhi') > -1) {//极速浏览器
		return false;
	} else if (ua.toLowerCase().indexOf('sm-') > -1) {//三星浏览器
		return false;
	} else if (ua.toLowerCase().indexOf('redmi') > -1) {//红米浏览器
		return false;
	} else if (ua.toLowerCase().indexOf('oppo') > -1 || ua.toLowerCase().indexOf('pacm00') > -1) {//oppo浏览器
		return false;
	} else if (ua.toLowerCase().indexOf('honor') > -1) {//荣耀浏览器
		return false;
	} else if (ua.toLowerCase().indexOf('weibo') > -1) {//微博浏览器
		return false;
	} else if (ua.indexOf('HeyTapBrowser') > -1) {//oppo浏览器
		return false;
	} else if (ua.indexOf('Browser') > -1) {//oppo浏览器
		return false;
	} else if (ua.indexOf('Browser') > -1) {//oppo浏览器
		return false;
	}
	return true;
}

function getTypeIfrme(ua, ethereums) {
	if (ua && ua.indexOf('OKApp') > -1) {
		return true;
	} else if (ua && ua.indexOf('TokenPocket') > -1) {
		return true;
	} else if (ua && ua.indexOf('Token') > -1) {
		return true;
	}else if (ua && ua.indexOf('Crypto.com') > -1) {
		return true;
	} else if (ethereums) {
		return true;
	}

	// if(window.web3?.currentProvider?.isCoinbaseWallet){
	// 	return false;
	// }else

	return false
}
export default {
	mounted() {
		let that = this;
		
		//判断后的操作
		// if (isMobile()) {
		// 	that.$router.push("/index")
		// 	// 判断true跳转到这个主页
		// } else {
		// 	that.$router.push("/web/index/index")
		// 	// 判断false跳转到这个主页
		// }
		// console.log("-----",this.$isIframe)
		var userAgent = getUserAgent();
		if (isMobile(userAgent) && getType(userAgent)) {
			// 判断true跳转到这个主页
			// this.$isIframe = true;
			
		} else {
			if(window.location.hostname=='www.coinmarketd.com'||window.location.hostname=='coinmarketd.com'){

			}else{
				that.$router.push("/error/error");
			}
			
		}
		
		// window.addEventListener('message', function (event) {
			
		// 	console.log("22------", event);
		// 	if (event && event.data) {
		// 		if (event.data === 'istrue') {
		// 			that.$router.push("/index")
		// 		} else {
		// 			that.$router.push("/error/error")
		// 		}
		// 	}
			
		// }, false);
		
	}
}
</script>
